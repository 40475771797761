import React from 'react';
import Redirect from 'react-router-dom'

import '../Styles/UserLogin.css';
import '../Styles/Globals.css';
import {traivaBlue, traivaOrange, traivaOrange2} from "../Files/colors";
import bgImage from "../Files/Background.png";
import logo from "../Files/logo.png";

class QuickLesson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonHover: false,
        }
    }
    login(url, lecture, lang) {
        this.props.history.push("/player", {
            userID: 0,
            lecture,
            url,
            lang
        })
    }
    simulateClick(e) {
        return e===null?null:
        e.click()
    }
    render() {
        return (
            <div style={{ flex: 1, backgroundColor: 'white', width: '100%', height: '100vh', position: 'absolute', zIndex: -20 }}>
                <img style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    backgroundSize: 'cover',
                    zIndex: -1,
                }} src={bgImage} />
                <div className="body">
                    <div className="mainAreaLogin">
                        <img style={{
                            height: 'auto',
                            width: 'auto',
                            maxHeight: window.innerHeight * 0.2
                        }} src={logo} />
<br />
                        <h2>{this.props.title}</h2>
                        <button
                            onMouseEnter={() => this.setState({ buttonHover: true })}
                            onMouseLeave={() => this.setState({ buttonHover: false })}
                            style={{
                                border: "none",
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingTop: 8,
                                paddingBottom: 8,
                                margin: 0,
                                textDecoration: "none",
                                backgroundColor: this.state.buttonHover ? traivaOrange2 : traivaOrange,
                                color: traivaBlue,
                                fontSize: window.innerWidth * 0.015,
                                textAlign: "center",
                                borderRadius: 3,
                                cursor: "pointer"
                            }}
                            onClick={() => this.login(this.props.successURL, this.props.kurz,this.props.lang)}>
                            {this.props.lang==='cze' && 'SPUSTIT'}
                            {this.props.lang==='eng' && 'LAUNCH'}
                        </button>
                        {
                            this.state.loginFailed ?
                                <div style={{color: 'red', marginTop: '20px'}}>PŘIHLÁŠENÍ SE NEZDAŘILO. ZKONTROLUJTE SVÉ PŘIHLAŠOVACÍ JMÉNO.</div>
                                : null
                        }

                    </div>
                </div>
            </div>
        )
    }
}



export default QuickLesson;
