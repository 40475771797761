import React from 'react';
import queryString from 'query-string';

import '../Styles/UserLogin.css';
import '../Styles/Globals.css';
import bgImage from '../Files/Background.png';
import logo from '../Files/logo.png';
import { traivaGreen, traivaBlue, traivaOrange, traivaOrange2, traivaPurple, traivaGrey } from '../Files/colors';

class UserLogin extends React.Component {
    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search);
        var usernameTemp = params.username == 'undefined' ? '' : params.username;

        this.state = {
            username: usernameTemp,
            password: '',
            buttonHover: false,
            loginFailed: false
        }
    }


    handleChange = (e) => {
        this.setState({
            //[e.target.name]: e.target.value
            username: e.target.value
        })
    }

    login = () => {

        //Used only for testing, when user enters test as username, uses local modul json
        if (this.state.username === "test") {
            this.props.history.push("/player", {
                userID: 0,
                lecture: 4
            })
            return;
        }

        //Fetch user and lecture from the safetutor server
        var url = `https://www.safetutor.cz/api/?username=${this.state.username}`;
        var userID = 0;
        var lecture = 0;

        console.log("Login Url " + url);

        fetch(url,
            {
                method: 'GET',
                headers: new Headers({
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*",
                    'Accept': 'application/json',
                    'Content-Type': 'text/plain',
                    'Origin': ''
                }),
                mode: 'cors'
            })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                //Go to Player and send user and lecture data there
                if(data.stav === "OK") {
                    this.props.history.push("/player", {
                        userID: data.id,
                        lecture: 'modul'+data.kurz
                    })
                } else {
                    this.setState({
                        loginFailed: true
                    })
                }

            })
            .catch(error => {
                console.log("Error Fetching User Data " + error);
            })
    }


    render() {
        return (
            <div style={{ flex: 1, backgroundColor: 'white', width: '100%', height: '100vh', position: 'absolute', zIndex: -20 }}>
                <img style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    backgroundSize: 'cover',
                    zIndex: -1,
                }} src={bgImage} />
                <div className="body">
                    <div className="mainAreaLogin">
                        <img style={{
                            height: 'auto',
                            width: 'auto',
                            maxHeight: window.innerHeight * 0.2
                        }} src={logo} />
                        <form className="formLogin">
                            <input
                                className="inputLogin"
                                name="username"
                                type="text"
                                placeholder="Uživatelské jméno"
                                value={this.state.username}
                                onChange={this.handleChange} />
                            {/*<input
                                className="inputLogin"
                                name="password"
                                type="text"
                                placeholder="Heslo"
                                value={this.state.password}
                            onChange={this.handleChange} />*/}
                        </form>
                        <button
                            onMouseEnter={() => this.setState({ buttonHover: true })}
                            onMouseLeave={() => this.setState({ buttonHover: false })}
                            style={{
                                border: "none",
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingTop: 8,
                                paddingBottom: 8,
                                margin: 0,
                                textDecoration: "none",
                                backgroundColor: this.state.buttonHover ? traivaOrange2 : traivaOrange,
                                color: traivaBlue,
                                fontSize: window.innerWidth * 0.015,
                                textAlign: "center",
                                borderRadius: 3,
                                cursor: "pointer"
                            }}
                            onClick={() => this.login()}>
                            PŘIHLÁSIT
                        </button>
                        {
                            this.state.loginFailed ?
                                <div style={{color: 'red', marginTop: '20px'}}>PŘIHLÁŠENÍ SE NEZDAŘILO. ZKONTROLUJTE SVÉ PŘIHLAŠOVACÍ JMÉNO.</div>
                                : null
                        }

                    </div>
                </div>
            </div>
        )
    }
}



export default UserLogin;
