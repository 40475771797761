import React from 'react';
import Fullscreen from 'react-full-screen';
import ReactPlayer from 'react-player';
import firebase from 'firebase';
import { IconContext } from "react-icons";
import { IoIosSettings } from "react-icons/io";
import { IoMdTime } from "react-icons/io";
import posed from 'react-pose';

import PauseMenu from './PauseMenu';
import { traivaGreen, traivaBlue, traivaOrange, traivaPurple, traivaGrey } from '../Files/colors';
import '../Styles/Globals.css';
import '../Styles/Player.css';

const AnswerAnim = posed.div({
    visible: {
        opacity: 1,
        y: 0,
        delay: 200,
        transition: {
            duration: 200,
            ease: 'linear'
        }
    },
    hidden: {
        opacity: 0.1,
        y: -300,
        delay: 200,
        transition: {
            duration: 200,
            ease: 'linear'
        }
    }
});

const QuestionAnim = posed.div({
    visible: {
        opacity: 1,
        y: 0,
        delay: 200,
        transition: {
            duration: 400,
            ease: 'linear'
        }
    },
    hidden: {
        opacity: 0.1,
        y: -300,
        delay: 200,
        transition: {
            duration: 400,
            ease: 'linear'
        }
    }
});

//Test User

class Player extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            number: null,
            loading: true,
            isFullscreen: false,
            objectIndex: 0,         //At what question we are currently at
            index: 0,               //progress index on current question. 0 - videoStart, 1 - question, 2 - videoEnd
            objects: [],
            questionAnswered: false,
            answerWasCorrect: false,
            loadedImage: null,
            completedModule: false,
            totalPoints: 0,
            totalMaxPoints: 0,
            paused: false,
            bgImageHeight: 0,
            bgImg: null,
            visible: false,
            buttonHoverIndex: -1,
            controls: false,
            pointsToPass: 0,
            userId: 0,
            modul: 0,
            stats: []
        }
    }

    setupTimer = () => {
        this.interval = setInterval(() => {
            if (!this.state.paused) {
                this.setState({ number: this.state.number + 1 })
            }
        },
            1000);

    }

    pause = () => {
        console.log("Pause");
        this.setState({ paused: true })
    }

    resume = () => {
        console.log("Resume");
        this.setState({ paused: false })
    }

    componentDidMount() {

        const img = new Image();
        //img.src = this.state.imageUrl;

        this.setState((state)=>{return {
            isFullscreen: !state.isFullscreen,
            loadedImage: img
        }})

        var userID = this.props.location.state.userID;
        var lecture = this.props.location.state.lecture;
        console.log("User ID " + userID);
        console.log("Modul " + lecture);


        var url = `https://www.safetutor.cz/api/${lecture}.json`;
        console.log("Json Url " + url);

        //Fetch Json

        fetch(url,
            {
                method: 'GET',
                headers: new Headers({
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*",
                    'Accept': 'application/json',
                    'Content-Type': 'text/plain',
                    'Origin': ''
                }),
                mode: 'cors'
            })
            .then(res => res.json())
            .then(data => {
                console.log(data);

                var jsonObjs = [];

                for (var key in data.objects) {

                    var obj = {
                        index: data.objects[key].index,
                        type: data.objects[key].type,
                        videoUrl: data.objects[key].videoUrl,

                        imageUrl: data.objects[key].imageUrl,
                        correctVideoUrl: data.objects[key].videoEndCorrectUrl,
                        wrongVideoUrl: data.objects[key].videoEndWrongUrl,
                        question: data.objects[key].question,
                        answers: null,
                        correctAnswer: data.objects[key].correctAnswer,
                        uiLayout: data.objects[key].uiLayout,

                        images: null,
                        correctImage: data.objects[key].correctImage,
                        wasAnsweredTimes: 0,
                        wasAnsweredCorrectly: false,
                        time: data.objects[key].time,
                        timeWarning: data.objects[key].timeWarning,
                        pointsMax: data.objects[key].pointsMax,
                        pointsSecondAttempt: data.objects[key].pointsSecondAttempt,
                        pointsTimePenalty: data.objects[key].pointsTimePenalty
                    }

                    var answersTemp = [];
                    for (var answer in data.objects[key].answers) {
                        answersTemp.push(
                            data.objects[key].answers[answer]
                        )
                    }
                    obj.answers = answersTemp;

                    var imagesTemp = [];
                    for (var imageKey in data.objects[key].images) {
                        var imgObj = {
                            baseImageUrl: data.objects[key].images[imageKey].baseImageUrl,
                            hihglightImagerUrl: data.objects[key].images[imageKey].hihglightImagerUrl,
                            width: data.objects[key].images[imageKey].width,
                            height: data.objects[key].images[imageKey].height,
                            scale: data.objects[key].images[imageKey].scale,
                            posFromTopinPercent: data.objects[key].images[imageKey].posFromTopinPercent,
                            posFromLeftinPercent: data.objects[key].images[imageKey].posFromLeftinPercent
                        }
                        imagesTemp.push(
                            imgObj
                        )

                    }
                    obj.images = imagesTemp;

                    jsonObjs.push(obj);
                }

                //Preload images
                jsonObjs.forEach(obj => {
                    const img = new Image();
                    img.src = obj.imageUrl;
                });

                this.setState(()=>{return {
                    objects: jsonObjs,
                    loading: false,
                    controls: data.controls,
                    pointsToPass: data.pointsToPassPercent,
                    userId: userID,
                    modul: lecture
                }})
            })
            .catch(error => {
                console.log("Error Fetching Json Data " + error);
            })



        //Test Local Json
        /*
                fetch(`./modul41.json`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(data => {
                        var jsonObjs = [];

                        console.log(data);

                        for (var key in data.objects) {

                            var obj = {
                                index: data.objects[key].index,
                                type: data.objects[key].type,
                                videoUrl: data.objects[key].videoUrl,

                                imageUrl: data.objects[key].imageUrl,
                                correctVideoUrl: data.objects[key].videoEndCorrectUrl,
                                wrongVideoUrl: data.objects[key].videoEndWrongUrl,
                                question: data.objects[key].question,
                                answers: null,
                                correctAnswer: data.objects[key].correctAnswer,
                                uiLayout: data.objects[key].uiLayout,

                                images: null,
                                correctImage: data.objects[key].correctImage,
                                wasAnsweredTimes: 0,
                                wasAnsweredCorrectly: false,
                                time: data.objects[key].time,
                                timeWarning: data.objects[key].timeWarning,
                                pointsMax: data.objects[key].pointsMax,
                                pointsSecondAttempt: data.objects[key].pointsSecondAttempt,
                                pointsTimePenalty: data.objects[key].pointsTimePenalty
                            }

                            var answersTemp = [];
                            for (var answer in data.objects[key].answers) {
                                answersTemp.push(
                                    data.objects[key].answers[answer]
                                )
                            }
                            obj.answers = answersTemp;

                            var imagesTemp = [];
                            for (var imageKey in data.objects[key].images) {
                                var imgObj = {
                                    baseImageUrl: data.objects[key].images[imageKey].baseImageUrl,
                                    hihglightImagerUrl: data.objects[key].images[imageKey].hihglightImagerUrl,
                                    width: data.objects[key].images[imageKey].width,
                                    height: data.objects[key].images[imageKey].height,
                                    scale: data.objects[key].images[imageKey].scale,
                                    posFromTopinPercent: data.objects[key].images[imageKey].posFromTopinPercent,
                                    posFromLeftinPercent: data.objects[key].images[imageKey].posFromLeftinPercent
                                }
                                imagesTemp.push(
                                    imgObj
                                )

                            }
                            obj.images = imagesTemp;

                            jsonObjs.push(obj);
                        }

                        //Preload images
                        jsonObjs.forEach(obj => {
                            const img = new Image();
                            img.src = obj.imageUrl;
                        });

                        this.setState({
                            objects: jsonObjs,
                            loading: false,
                            controls: data.controls,
                            pointsToPass: data.pointsToPassPercent
                        })
                    })*/
    }


    updateIndex = () => {
        //If its video, end now and go to next one
        if (this.state.objects[this.state.objectIndex].type === "video") {
            var tempObjectIndex = this.state.objectIndex + 1;
            var CompletedModule = false;

            if (tempObjectIndex >= this.state.objects.length) {
                CompletedModule = true;
                this.completedLogic();
            }

            this.setState({
                objectIndex: tempObjectIndex,
                index: 0,
                questionAnswered: false,
                answerWasCorrect: false,
                completedModule: CompletedModule,
                visible: false
            }, () => this.forceUpdate())
        } else {
            //If its not video go to next index for current question/object
            var tempIndex = this.state.index + 1;
            if (tempIndex > 2) { tempIndex = 0; }

            //console.log("NEW QUESTION");
            clearInterval(this.interval);
            this.setupTimer();


            this.setState({
                number: 0,
                index: tempIndex,
                visible: false
            })
        }
    }

    questionEnded = () => {
        var tempObjectIndex = this.state.objectIndex + 1;
        var CompletedModule = false;

        var tempIndex = this.state.index + 1;
        if (tempIndex > 2) { tempIndex = 0; }

        if (tempObjectIndex >= this.state.objects.length) {
            CompletedModule = true;
            this.completedLogic();
        }

        this.setState({
            objectIndex: tempObjectIndex,
            index: tempIndex,
            questionAnswered: false,
            answerWasCorrect: false,
            completedModule: CompletedModule
        })
    }

    completedLogic = () => {

        /*
        Vypocet bodu
        spravne zodpovezena otazka: +pointsMax
        spravne zodpovezena otazka na druhy pokus: +pointsSecondAttempt
        spravne zodpovezena otazka na treti pokus: 0
        zodpovezeni otazka po casovem limitu (cervene hodiny): -3
        spatne zodpovezena otazka: 0
        */

        var statsString = '';

        Object.keys(this.state.stats).forEach(key => {
            statsString += 'Obj';
            statsString += ':';
            statsString += this.state.stats[key].objectIndex;
            statsString += ':';
            statsString += this.state.stats[key].selectedAnswer;
            statsString += ':';
            statsString += (this.state.stats[key].wasCorrect ? '1' : '0');
            statsString += ':';
            statsString += this.state.stats[key].time;
            statsString += ';';
        })

        var userPointsPercentFromMax = (this.state.totalPoints / this.state.totalMaxPoints) * 100;
        var userPassed = (userPointsPercentFromMax >= this.state.pointsToPass) ? "1" : "0";

        var pointsStatsString = '';
        pointsStatsString += this.state.totalMaxPoints; //Max points for whole module
        pointsStatsString += ':';
        pointsStatsString += this.state.totalPoints; //User points
        pointsStatsString += ':';
        pointsStatsString += this.state.pointsToPass;
        pointsStatsString += ':';
        pointsStatsString += userPassed;
        pointsStatsString += ';'

        var url = `https://www.safetutor.cz/api/?finish_id=${this.state.userId}&mod_id=${this.state.modul}&points=${pointsStatsString}&stats=${statsString}`;

        console.log("User Pass Percent " + userPointsPercentFromMax);
        console.log("Max Points " + this.state.totalMaxPoints);
        console.log("User Passed " + userPassed);
        console.log("Points String " + pointsStatsString);
        console.log("Stats String " + statsString);
        console.log(url);

        //url = this.props.location.state.url ? this.props.location.state.url : url;
        window.location.href = url;
    }

    setPoints = (times, wasCorrect, selectedAnswerIndex) => {
        var points = 0;

        switch (times) {
            case 1:
                points = this.state.objects[this.state.objectIndex].pointsMax;
                break;
            case 2:
                points = this.state.objects[this.state.objectIndex].pointsSecondAttempt;
                break;
            default:
                points = 0;
                break;
        }

        if (this.state.number > this.state.objects[this.state.objectIndex].time) {
            points -= this.state.objects[this.state.objectIndex].pointsTimePenalty;
        }

        if (!wasCorrect) { points = 0; }
        if (points < 0) { points = 0; }

        var newPoints = this.state.totalPoints + points;

        // console.log("Points " + newPoints);

        //Calculate how many,
        var curTotalMaxPoints = this.state.totalMaxPoints;
        //Increase the points only if answer was correct, so that points are not added each time for wrong answer
        if (wasCorrect) {
            curTotalMaxPoints += this.state.objects[this.state.objectIndex].pointsMax;
        }


        //Set stats
        var statsObj = {
            objectIndex: this.state.objects[this.state.objectIndex].index,
            selectedAnswer: selectedAnswerIndex,
            wasCorrect: wasCorrect,
            time: this.state.number,
        };

        var statsArray = this.state.stats;
        statsArray.push(statsObj);

        this.setState({
            totalPoints: newPoints,
            stats: statsArray,
            totalMaxPoints: curTotalMaxPoints
        });
    }

    selectAnswer = (index) => {
        var wasCorrect = index === this.state.objects[this.state.objectIndex].correctAnswer ? true : false;

        //store the new value, because when question is supposed to be repeated, we keep the old and the new one in array for each fail. This way
        //there are duplicates of questions ( necessary for palyer flow paying ending video ). For that reason wasShown would be added for each duplicate
        //in the array, cant be added in the map, but outside
        var times = this.state.objects[this.state.objectIndex].wasAnsweredTimes + 1;

        if (!this.state.questionAnswered) {
            this.setPoints(times, wasCorrect, index);

            this.setState(prevState => ({
                objects: prevState.objects.map(
                    obj => (obj.index === this.state.objects[this.state.objectIndex].index ? Object.assign(obj, {
                        wasAnsweredTimes: times,
                        wasAnsweredCorrectly: wasCorrect
                    }) : obj)
                ),
                questionAnswered: true,
                answerWasCorrect: wasCorrect,
                //visible: false
            }), () => this.shouldRepeatQuestion(wasCorrect))
        }
    }

    shouldRepeatQuestion = (wasCorrect) => {
        //Answer was incorrect, repeat the question byt putting it again in the array of objects
        if (!wasCorrect) {
            //store data
            var question = this.state.objects[this.state.objectIndex];
            var objs = this.state.objects;
            var newIndex = this.state.objectIndex + 1;

            //Put question between current index and index of last object
            var maxIndex = this.state.objects.length;
            var minIndex = this.state.objectIndex + 1;
            var randomIndex = this.getRandomInt(minIndex, maxIndex);

            //move the same question to new index, keep the old question, so it does not mess up anything.
            objs.splice(randomIndex, 0, question);

            this.setState(prevState => ({
                objects: objs
            }))
        }
    }

    getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    selectImage = (index) => {
        var wasCorrect = index === this.state.objects[this.state.objectIndex].correctImage ? true : false;

        var times = this.state.objects[this.state.objectIndex].wasAnsweredTimes + 1;

        if (!this.state.questionAnswered) {
            this.setPoints(times, wasCorrect, index);

            this.setState(prevState => ({
                objects: prevState.objects.map(
                    obj => (obj.index === this.state.objects[this.state.objectIndex].index ? Object.assign(obj, {
                        wasAnsweredTimes: times,
                        wasAnsweredCorrectly: wasCorrect
                    }) : obj)
                ),
                questionAnswered: true,
                answerWasCorrect: wasCorrect,
                //visible: false
            }), () => this.shouldRepeatQuestion(wasCorrect))
        }
    }

    showEndVideo = () => {
        this.updateIndex();
    }

    renderSwitch() {
        var buttonsWidthPool = 72;
        var buttonCount = this.state.objects[this.state.objectIndex].answers.length;
        var buttonIndex = -1;
        var iconIndex = -1;
        var buttonWidth = buttonsWidthPool / buttonCount;
        var buttonSpacing = (100 - buttonsWidthPool) / buttonCount;
        var width = window.innerWidth;
        var top = this.state.objects[this.state.objectIndex].uiLayout === "top" ? 10 : 85;
        var bgImgHeight = this.state.bgImg !== null ? this.state.bgImg.height : 0;
        var bgImgWidth = this.state.bgImg !== null ? this.state.bgImg.width : 0;
        // var questionFontSize = 2 +((width * 0.02) / Math.max(1, (this.state.objects[this.state.objectIndex].question.length / 160)));
        var questionFontSize = '2em';


        switch (this.state.index) {
            case 0:
                var videoUrl = this.state.objects[this.state.objectIndex].videoUrl;
                return (
                    videoUrl.length > 0 ?
                        <div className={'player-wrapper'}> <ReactPlayer
                            className="player"
                            url={videoUrl}
                            playing={true}
                            playsinline={true}
                            width='100%'
                            height='100%'
                            controls={this.state.controls}
                            onEnded={() => this.updateIndex()}
                        /></div>
                        : this.updateIndex()
                )
                break;
            case 1:
                return (
                    this.state.objects[this.state.objectIndex].type === "question"
                        ?
                        <div
                            onLoad={() => this.setState({ visible: true })}>
                            <QuestionAnim
                                pose={this.state.visible ? 'visible' : 'hidden'}
                                style={{
                                    fontSize: questionFontSize,
                                    textAlign: "center",
                                    paddingBottom: window.innerHeight * 0.01,
                                    marginLeft: window.innerWidth * 0.02, //relates to width (width + marginLeft * 2) ==  100
                                    width: window.innerWidth * 0.96,
                                    borderRadius: 10,
                                    height: "10%",
                                    position: "absolute",
                                    top: "2%",
                                    zIndex: 10,
                                    color: traivaGrey,
                                    backgroundColor: traivaBlue,

                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}>
                                {this.state.objects[this.state.objectIndex].question}
                            </QuestionAnim>
                            {
                                this.state.objects[this.state.objectIndex].answers.map((answer, key) => {
                                    buttonIndex += 1;
                                    var bgColor = traivaGrey;
                                    var textColor = traivaBlue;
                                    var isCorrect = this.state.objects[this.state.objectIndex].correctAnswer - 1 === key;

                                    var spacingValue = buttonSpacing / 2 + (buttonSpacing * buttonIndex) + (buttonWidth * buttonIndex);

                                    if (this.state.questionAnswered) {
                                        bgColor = isCorrect ? traivaGreen : traivaGrey
                                        textColor = isCorrect ? traivaBlue : traivaBlue
                                    }

                                    return (
                                        <AnswerAnim
                                            pose={this.state.visible ? 'visible' : 'hidden'}
                                            onMouseEnter={() => this.setState({ buttonHoverIndex: key })}
                                            onMouseLeave={() => this.setState({ buttonHoverIndex: 100 })}
                                            key={key}
                                            style={{
                                                border: 'none',
                                                paddingTop: '1%',
                                                paddingBottom: '1%',
                                                paddingLeft: '0.5%',
                                                paddingRight: '0.5%',
                                                marginTop: "4%",
                                                textDecoration: 'none',
                                                backgroundColor: (this.state.buttonHoverIndex === key) ? traivaOrange : (bgColor),
                                                color: textColor,
                                                fontSize: '1.4em',
                                                textAlign: 'center',
                                                borderRadius: 10,
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                width: `${buttonWidth}%`,
                                                top: `4.5%`, //${top}%`
                                                left: `${buttonSpacing / 2 + (buttonSpacing * buttonIndex) + (buttonWidth * buttonIndex) - (0.5 * buttonIndex)}%`,
                                                zIndex: 12
                                            }}
                                            onClick={() => this.selectAnswer((key + 1))}>
                                            {this.state.objects[this.state.objectIndex].answers[buttonIndex]}
                                        </AnswerAnim>
                                    )

                                })
                            }
                            {this.state.questionAnswered
                                ? <div
                                    className={this.state.answerWasCorrect ? "tintGreen" : "tintRed"}
                                    onClick={() => this.showEndVideo()}>
                                </div>
                                : null
                            }

                            {this.state.questionAnswered
                                ? <div style={{ pointerEvents: 'none', position: 'absolute', textAlign: "center", color: traivaGrey, opacity: 1, fontSize: window.innerHeight * 0.05, marginTop: window.innerHeight * 0.45, width: '100%', zIndex: 20 }}>
                                    {this.props.location.state.lang === 'eng'? "Click anywhere to continue" : 'Klikněte kdekoliv pro pokračování'}
                                </div>
                                : null
                            }

                            <img
                                alt=""
                                src={this.state.objects[this.state.objectIndex].imageUrl}
                                className="imgQuestion" />

                            <PauseMenu myPause={this.pause} myResume={this.resume} />
                            <div className="timer">
                                {/*<div>{this.state.number}</div>*/}
                                {
                                    this.state.number > this.state.objects[this.state.objectIndex].time
                                        ? <IconContext.Provider value={{ className: "timerRed" }}>
                                            <IoMdTime />
                                        </IconContext.Provider>
                                        : this.state.number > this.state.objects[this.state.objectIndex].timeWarning
                                            ? <IconContext.Provider value={{ className: "timerWhite" }}>
                                                <IoMdTime />
                                            </IconContext.Provider>
                                            : null
                                }
                            </div>
                            {/*<div className="points">{this.state.totalPoints}</div>
                            <div className="index">{this.state.objectIndex + 1}</div>*/}
                        </div>
                        : <div onLoad={() => this.setState({ visible: true })}>
                            {
                                this.state.objects[this.state.objectIndex].images.map((image, key) => {
                                    iconIndex += 1;

                                    return (
                                        <img
                                            alt=""
                                            src={image.baseImageUrl}
                                            key={key}
                                            style={{
                                                position: 'absolute',
                                                width: image.width * image.scale * (bgImgWidth / 1000),
                                                height: image.height * image.scale * (bgImgWidth / 1000),
                                                cursor: 'pointer',
                                                top: bgImgHeight / 100 * image.posFromTopinPercent, //window.innerHeight / 100 * image.posFromTopinPercent,
                                                left: bgImgWidth / 100 * image.posFromLeftinPercent, //window.innerWidth / 100 * image.posFromLeftinPercent,
                                                zIndex: 2,
                                                color: "red"
                                            }}
                                            onMouseOver={e => (e.currentTarget.src = image.hihglightImagerUrl)}
                                            onMouseOut={e => (e.currentTarget.src = image.baseImageUrl)}
                                            onClick={() => this.selectImage((key + 1))} />

                                    )

                                })
                            }

                            {this.state.questionAnswered
                                ? <div
                                    className={this.state.answerWasCorrect ? "tintGreen" : "tintRed"}
                                    onClick={() => this.showEndVideo()}></div>
                                : null
                            }

                            {this.state.questionAnswered
                                ? <div style={{ pointerEvents: 'none', position: 'absolute', textAlign: "center", color: traivaGrey, opacity: 1, fontSize: window.innerHeight * 0.05, marginTop: window.innerHeight * 0.45, width: '100%', zIndex: 20 }}>
                                    Klikněte kdekoliv pro pokračování
                                </div>
                                : null
                            }

                            <QuestionAnim
                                pose={this.state.visible ? 'visible' : 'hidden'}
                                style={{
                                    fontSize: questionFontSize,
                                    textAlign: "center",
                                    paddingBottom: window.innerHeight * 0.01,
                                    marginLeft: window.innerWidth * 0.02, //relates to width (width + marginLeft * 2) ==  100
                                    width: window.innerWidth * 0.96,
                                    borderRadius: 10,
                                    height: "10%",
                                    position: "absolute",
                                    top: "2%",
                                    zIndex: 10,
                                    color: traivaGrey,
                                    backgroundColor: traivaBlue,

                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}>
                                {this.state.objects[this.state.objectIndex].question}
                            </QuestionAnim>
                            <img
                                alt=""
                                src={this.state.objects[this.state.objectIndex].imageUrl}
                                ref={el => this.imgEl = el}
                                onLoad={() => this.setState({ bgImg: this.imgEl })}
                                className="imgQuestion" />

                            <PauseMenu myPause={this.pause} myResume={this.resume} />
                            <div className="timer">
                                {/*<div>{this.state.number}</div>*/}
                                {
                                    this.state.number > this.state.objects[this.state.objectIndex].time
                                        ? <IconContext.Provider value={{ className: "timerRed" }}>
                                            <IoMdTime />
                                        </IconContext.Provider>
                                        : this.state.number > this.state.objects[this.state.objectIndex].timeWarning
                                            ? <IconContext.Provider value={{ className: "timerWhite" }}>
                                                <IoMdTime />
                                            </IconContext.Provider>
                                            : null
                                }
                            </div>
                            {/*<div className="points">{this.state.totalPoints}</div>
                            <div className="index">{this.state.objectIndex + 1}</div>*/}
                        </div>
                )
                break;
            case 2:
                var endUrl = this.state.answerWasCorrect
                    ? this.state.objects[this.state.objectIndex].correctVideoUrl
                    : this.state.objects[this.state.objectIndex].wrongVideoUrl;

                return (
                    endUrl.length > 0 ?
                        <ReactPlayer
                            playsinline={true}
                            className="player"
                            url={endUrl}
                            playing
                            width='100%'
                            height='100%'
                            controls={this.state.controls}
                            onEnded={() => this.questionEnded()}
                        />
                        : this.questionEnded()
                )
                break;
            default:
                console.log("case default")
                return null;
        }
    }

    redirect (url){
        window.location.href = url
    }

    render() {
        return (
            <Fullscreen
                enabled={this.state.isFullscreen}
                onChange={isFullscreen => this.setState({ isFullscreen })}
            >
                <div className="full-screenable-node">
                    <div className="body">
                        {
                            this.state.loading
                                ? null
                                :
                                (this.state.objects.length > 0 && this.state.objectIndex < this.state.objects.length)
                                    ? this.renderSwitch()
                                    : (this.props.location.state.url? this.redirect(this.props.location.state.url) : <div style={{
                                        fontSize: window.innerWidth * 0.03,
                                        margin: 0,
                                        textAlign: "center",
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        textDecoration: "none",
                                        color: "#353743",
                                        backgroundColor: "#EBB04B",

                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        MODUL DOKONČEN
                                </div>)
                        }
                    </div>
                </div>
            </Fullscreen>
        )
    }
}

export default Player;

