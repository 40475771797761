import React from 'react';
import Fullscreen from 'react-fullscreen-crossbrowser';
import firebase from 'firebase';
import posed from 'react-pose';
import queryString from 'query-string';

import '../Styles/StartScreen.css';
import triangleIcon from '../Files/Images/TriangleIcon.svg';
import bgImage from '../Files/Background.png';
import audioTest from '../Files/AUDIO_TEST.mp3';
import { traivaGreen, traivaBlue, traivaOrange, traivaOrange2, traivaPurple, traivaGrey } from '../Files/colors';

const Box = posed.div({
    visible: {
        opacity: 1,
        transition: {
            duration: 300,
            ease: 'linear'
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            type: 'spring', stiffness: 100
        }
    }
});

class StartScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFull: false,
            file: null,
            fileProgress: 0,
            visible: true,
            buttonHover: false,
            usernameValue: ''
        }
        this.audio = new Audio(audioTest)

    }

    async componentDidMount() {
        this.audio.addEventListener('ended', () => this.setState({ play: false }));
        /*
        var url = "https://www.traiva.cz/safetutor/api/?finish_id=13&course_id=1&points=10";

        fetch(url, {
            method: 'GET',
            headers: new Headers({
                "Access-Control-Request-Headers": "*",
                "Access-Control-Request-Method": "*",
                'Accept': 'application/json',
                'Content-Type': 'text/plain',
                'Origin': ''
            }),
            mode: 'cors'
        })
            .then(response => console.log(response))*/

        //Get Data from Url after ?
        let params = queryString.parse(this.props.location.search);
        console.log(params.username);
        this.setState({
            usernameValue: params.username
        })
    }
    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({ play: false }));
    }

    onChangeFile = (e) => {
        this.setState({ file: e.target.files[0] })
    }
    togglePlay = () => {
        this.setState({ play: !this.state.play }, () => {
            this.state.play ? this.audio.play() : this.audio.pause();
        });
    }
    /*
    handleUpload = (e) => {
        e.preventDefault();

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                this.setState({ fileProgress: progress });
            },
            (error) => {
                console.log(error)

            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log(" Upload Complete " + downloadURL)
                })
            })
    }*/

    spustit = () => {
        this.props.history.push(`/login?username=${this.state.usernameValue}`)
        //this.setState({ visible: !this.state.visible })
    }

    render() {
        var logoScale = 0.0002;
        return (
                <div style={{ flex: 1, backgroundColor: 'white', width: '100%', height: '100vh', position: 'absolute', zIndex: -20 }}>
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',

                        width: '100%',
                        height: '100%',
                        opacity: 1,
                        margin: 0
                    }}>
{/*}
                        <img style={{
                            position: 'absolute',
                            height: window.innerWidth * 0.3,
                            width: window.innerWidth * 0.3,
                            left: '3.5%',
                            zIndex: -1,
                            top: '1%'
                        }} src={triangleIcon} /> */}
                        <img style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundSize: 'cover',
                            zIndex: -1,
                        }} src={bgImage} />
                        <div className="body">



                            <div style={{
                                width:"45vw",
                                position: 'absolute',
                                top: '7%',
                                left: '30%',
                                color: '#353743',
                                fontFamily: 'Ubuntu, Ubuntu mono, Arial'
                            }}>
                                <img
                                    style={{
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        position:'relative',
                                        left:'25%',
                                        margin: 0,
                                        textAlign: 'center',
                                        width: (2301 * window.innerWidth * logoScale),
                                        height: (736 * window.innerWidth * logoScale),
                                        //minWidth: window.innerWidth * 0.1,
                                        //maxWidth: window.innerWidth * 0.4
                                    }}
                                    src={require('../Files/Title2.png')}
                                    alt="Title Logo" />
                                <div style={{
                                    position:'relative',
                                    left:'30%',
                                }}>
                                    <p>
                                        K tomuto kurzu je nutné zapnout zvuk. Ujistěte se, že máte zvuk zapnutý a reproduktory nebo sluchátka připojena k PC.
                                    </p>
                                </div>
                                <div style={{
                                    position:'relative',
                                    left:'25%',
                                }}>
                                <h1>Audio Test</h1>
                                </div>
                                <div style={{
                                    position:'relative',
                                    left:'35%',
                                }}>
                                <a className={'playButton'} onClick={this.togglePlay}> </a>
                                </div>
                                <div style={{
                                    position:'relative',
                                    left:'10%',
                                }}>
                                <p>Zde prosím vyzkoušejte, zda bez problému rozumíte zvukové stopě, a nastavte si na liště hlasitost dle potřeby.</p>
                                    </div>
                                    <button
                                        onMouseEnter={() => this.setState({ buttonHover: true })}
                                        onMouseLeave={() => this.setState({ buttonHover: false })}
                                        style={{
                                            border: "none",
                                            paddingLeft: window.innerWidth * 0.04,
                                            paddingRight: window.innerWidth * 0.04,
                                            paddingTop: 8,
                                            display:'block',
                                            paddingBottom: 8,
                                            textDecoration: "none",
                                            backgroundColor: this.state.buttonHover ? traivaOrange2 : traivaOrange,
                                            color: traivaBlue,
                                            fontSize: window.innerWidth * 0.03,
                                            textAlign: "center",
                                            borderRadius: 10,
                                            cursor: "pointer",
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            position:'relative',
                                            left:'-15%',
                                        }}
                                        onClick={() => this.spustit()}>
                                        SPUSTIT
                                    </button>

                            </div>

                        </div>
                    </div>
                </div>
        )
    }

    StartApp = () => {
        alert("test")
    }

}

export default StartScreen;

