import React from 'react';
import queryString from 'query-string';

import '../Styles/UserLogin.css';
import s from '../index.css';
import bgImage from '../Files/Background.png';
import logo from '../Files/logo.png';
import { traivaGreen, traivaBlue, traivaOrange, traivaOrange2, traivaPurple, traivaGrey } from '../Files/colors';

class DemoPage extends React.Component {



    render() {
        return (
            <div style={{ flex: 1, backgroundColor: 'white', width: '100%', height: '100vh', position: 'absolute', zIndex: -20 }}>
                <img style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    backgroundSize: 'cover',
                    zIndex: -1,
                }} src={bgImage} />
                <div className="body">
                    <div className="mainAreaLogin">
                        <img style={{
                            height: 'auto',
                            width: 'auto',
                            maxHeight: window.innerHeight * 0.2
                        }} src={logo} />
                        <button className='btn' style={{fontSize: window.innerWidth * 0.015}} onClick={()=>this.props.history.push("/demo-online_kurz_bozp_zakladni")}>
                            Kurz BOZP Základní
                        </button>

                        <button className='btn' style={{fontSize: window.innerWidth * 0.015}} onClick={()=>this.props.history.push("/demo-online_kurz_obrabeci_stroje")}>
                            Kurz Obráběcí Stroje
                        </button>

                        <button className='btn' style={{fontSize: window.innerWidth * 0.015,}} onClick={()=>this.props.history.push("/demo-online_kurz_bozp_vedouci")}>
                            Kurz BOZP Vedoucí
                        </button>

                        <button className='btn' style={{fontSize: window.innerWidth * 0.015,}} onClick={()=>this.props.history.push("/demo-online_kurz_bozp_covid19")}>
                            Kurz BOZP Covid19
                        </button>

                        <button className='btn' style={{fontSize: window.innerWidth * 0.015,}} onClick={()=>this.props.history.push("/demo-online_kurz_bozp_ridici")}>
                            Kurz BOZP Řídící
                        </button>


                    </div>
                </div>
            </div>
        )
    }
}



export default DemoPage;
