import React from 'react';
import { withRouter } from "react-router-dom";
import { IconContext } from "react-icons";
import { IoIosSettings } from "react-icons/io";
import { IoMdPause } from "react-icons/io";
import { IoMdPlay } from "react-icons/io";
import { IoMdHelp } from "react-icons/io";
import { IoMdPower } from "react-icons/io";

import '../Styles/Globals.css';
import '../Styles/Player.css';

class PauseMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            paused: false,
            showHelp: false,
            quit: false
        }
    }

    expand = () => {
        this.setState({
            expanded: !this.state.expanded,
            paused: false,
            showHelp: false,
            quit: false
        })
    }

    pause = () => {
        this.setState({
            paused: true,
            showHelp: false,
            quit: false
        })
        this.props.myPause();
    }

    resume = () => {
        this.setState({
            paused: false,
            showHelp: false,
            quit: false
        })
        this.props.myResume();
    }

    showHelp = () => {
        this.setState({
            showHelp: !this.state.showHelp,
            quit: false
        })
    }

    quit = () => {
        this.setState({
            quit: !this.state.quit,
            showHelp: false
        })
    }

    quitConfrim = () => {
        this.props.history.push("/")
    }

    render() {
        return (
            <div className="pauseMenu">
                <IconContext.Provider value={{ className: "buttonMenu" }}>
                    <button className="buttonMenuClickable" onClick={() => this.expand()}>
                        <IoIosSettings />
                    </button>
                    {
                        this.state.expanded
                            ? <div className="pauseMenuFlex">
                                {this.state.paused
                                    ? <div className="pauseButtonParentDiv">
                                        <IconContext.Provider value={{ className: "buttonPause" }}>
                                            <button className="buttonMenuClickable" onClick={() => this.resume()}>
                                                <IoMdPlay />
                                            </button>
                                        </IconContext.Provider>
                                    </div>
                                    : <div className="pauseButtonParentDiv">
                                        <IconContext.Provider value={{ className: "buttonPause" }}>
                                            <button className="buttonMenuClickable" onClick={() => this.pause()}>
                                                <IoMdPause />
                                            </button>
                                        </IconContext.Provider>
                                    </div>
                                }
                                <div className="pauseButtonParentDiv">
                                    <IconContext.Provider value={{ className: "buttonPause" }}>
                                        <button className="buttonMenuClickable" onClick={() => this.showHelp()}>
                                            <IoMdHelp />
                                        </button>
                                    </IconContext.Provider>
                                </div>
                                <div className="pauseButtonParentDiv">
                                    <IconContext.Provider value={{ className: "buttonPause" }}>
                                        <button className="buttonMenuClickable" onClick={() => this.quit()}>
                                            <IoMdPower />
                                        </button>
                                    </IconContext.Provider>
                                </div>
                            </div>
                            : null
                    }
                </IconContext.Provider>
                {
                    this.state.showHelp
                        ? <div className="help">
                            <div className="helpText">Napoveda</div>
                        </div>
                        : null
                }
                {
                    this.state.quit
                        ? <div className="quit">
                            <div className="quitText">Ukoncit program.</div>
                            <div className="quitText2">( ukoncenim programu ztratite vsechna data)</div>
                            <button className="buttonQuitConfrim" onClick={() => this.quitConfrim()}>
                                <div className="confrimQuit">Potvrdit</div>
                            </button>
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default withRouter(PauseMenu);