export var traivaGreen = "#c8e300";
export var traivaGreen2 = "#84e52a";
export var traivaOrange = "#e8b14a";
export var traivaOrange2 = "#F2BE5D";
export var traivaBlue = "#373749";
export var traivaPurple = "#84486d";
export var traivaRed = "#ff225b";
export var traivaGrey = "#dedee1";


/* "traivaGreen" = "#c8e300";
    "traivaOrange" = "#e8b14a";
    "traivaBlue" = "#373749";
    "traivaPurple" = "#84486d";
    "traivaGrey" = "#dedee1"; */