import React from 'react';
import Redirect from 'react-router-dom'

import '../Styles/UserLogin.css';
import '../Styles/Globals.css';
import {traivaBlue, traivaOrange, traivaOrange2} from "../Files/colors";
import bgImage from "../Files/Background.png";
import logo from "../Files/logo.png";

class QuickLesson extends React.Component {
    constructor(props) {
        console.log(props);
        super(props);
        this.state = {
            buttonHover: false,
        }
    }
    componentDidMount() {



    }

    login = () => {
        var url = `https://www.safetutor.cz/api/?username=${this.props.username}`;
        return fetch(url,
            {
                method: 'GET',
                headers: new Headers({
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*",
                    'Accept': 'application/json',
                    'Content-Type': 'text/plain',
                    'Origin': ''
                }),
                mode: 'cors'
            })
            .then(res => res.json())
            .then(data => {
                if(data.stav === "OK") {
                    console.log(data);
                    this.props.history.push("/player", {
                        userID: data.id,
                        lecture: "modul" + data.kurz,
                        url: this.props.successURL,
                        lang: this.props.lang
                    })
                } else {
                    this.setState({
                        loginFailed: true
                    })
                }

            })
            .catch(error => {
                console.log("Error Fetching User Data " + error);
            })
    }
    simulateClick(e) {
        return e===null?null:
        e.click()
    }
    render() {
        // setTimeout(()=>{ this.login() }, 3000);
        return (
            <div style={{ flex: 1, backgroundColor: 'white', width: '100%', height: '100vh', position: 'absolute', zIndex: -20 }}>
                <img style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    backgroundSize: 'cover',
                    zIndex: -1,
                }} src={bgImage} />
                <div className="body">
                    <div className="mainAreaLogin">
                        <img style={{
                            height: 'auto',
                            width: 'auto',
                            maxHeight: window.innerHeight * 0.2
                        }} src={logo} />
<br />
                        {this.props.lang==='cze' && <h2>Školení COVID 19</h2>}
                        {this.props.lang==='eng' && <h2>Safety training COVID 19</h2>}
                        <button
                            onMouseEnter={() => this.setState({ buttonHover: true })}
                            onMouseLeave={() => this.setState({ buttonHover: false })}
                            style={{
                                border: "none",
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingTop: 8,
                                paddingBottom: 8,
                                margin: 0,
                                textDecoration: "none",
                                backgroundColor: this.state.buttonHover ? traivaOrange2 : traivaOrange,
                                color: traivaBlue,
                                fontSize: window.innerWidth * 0.015,
                                textAlign: "center",
                                borderRadius: 3,
                                cursor: "pointer"
                            }}
                            onClick={() => this.login()}>
                            {this.props.lang==='cze' && 'SPUSTIT'}
                            {this.props.lang==='eng' && 'LAUNCH'}
                        </button>
                        {
                            this.state.loginFailed ?
                                <div style={{color: 'red', marginTop: '20px'}}>PŘIHLÁŠENÍ SE NEZDAŘILO. ZKONTROLUJTE SVÉ PŘIHLAŠOVACÍ JMÉNO.</div>
                                : null
                        }

                    </div>
                </div>
            </div>
        )
    }
}



export default QuickLesson;
