import React from 'react';
import './Styles/App.css';
import './Styles/Globals.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import firebase from 'firebase';
import 'firebase/auth';


import StartScreen from './Components/StartScreen';
import UserLogin from './Components/UserLogin';
import Player from './Components/Player';
import QuickLesson from './Components/QuickLesson';
import Demo from './Components/Demo';
import DemoPage from './Components/DemoPage';


var config = {
  apiKey: "AIzaSyBTA4URDzDp9wh2lsgmnGwwyTTn7gqLRj0",
  authDomain: "safetutor-84a4c.firebaseapp.com",
  projectId: "safetutor-84a4c",
  appId: "1:1024834208810:web:7042359c83b5c04dae2f53"
};

firebase.initializeApp(config);


class App extends React.Component {

  componentWillMount() {
    if (!firebase.apps.length) {
        console.log("test");
    }
  }

  render() {
    return (
      <div className="App">
        <Router>
          <div className="routes">
            <Route exact path="/" component={StartScreen} />
            <Route path="/login" component={UserLogin} />
            <Route path="/player" component={Player} />
            <Route path="/covid19" component={(props)=><QuickLesson {...props} lang={'cze'} username={"U30F2XL443"} successURL={"https://www.safetutor.org/?page_id=2928"} />} />
            <Route path="/covid19_eng" component={(props)=><QuickLesson {...props} lang={'eng'} username={"U31F2XE93E"} successURL={"https://www.safetutor.org/corona-end-eng"} />} />
            <Route path="/demo-online_kurz_bozp_zakladni" component={(props)=><Demo {...props} lang={'cze'} title={'Kurz BOZP Základní'} successURL={"https://www.safetutor.cz/vice"} kurz={'demo-online_kurz_bozp_zakladni'} />} />
            <Route path="/demo-online_kurz_obrabeci_stroje" component={(props)=><Demo {...props} lang={'cze'} title={'Kurz Obráběcí Stroje'} successURL={"https://www.safetutor.cz/vice"} kurz={'demo-online_kurz_obrabeci_stroje'} />} />
            <Route path="/demo-online_kurz_bozp_vedouci" component={(props)=><Demo {...props} lang={'cze'} title={'Kurz BOZP Vedoucí'} successURL={"https://www.safetutor.cz/vice"} kurz={'demo-online_kurz_bozp_vedouci'} />} />
            <Route path="/demo-online_kurz_bozp_covid19" component={(props)=><Demo {...props} lang={'cze'} title={'Kurz BOZP Covid19'} successURL={"https://www.safetutor.cz/vice"} kurz={'demo-online_kurz_bozp_covid19'} />} />
            <Route path="/demo-online_kurz_bozp_ridici" component={(props)=><Demo {...props} lang={'cze'} title={'Kurz BOZP Řídící'} successURL={"https://www.safetutor.cz/vice"} kurz={'demo-online_kurz_bozp_ridici'} />} />
            <Route path="/testing" component={(props)=><Demo {...props} lang={'cze'} title={'Testing'} successURL={"https://www.safetutor.cz/vice"} kurz={'testing'} />} />
            <Route path="/vice" component={DemoPage} />

          </div>
        </Router>
      </div>
    );
  }
}

export default App;
